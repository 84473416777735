<template>
  <div class="container p-0" v-if="formSection == 1">
    <div class="row">
      <div class="col-7 p-4 border-right">
        <div v-if="currentEmployeeStep <= maxEmployeeStep">
          <p><b>{{ currentEmployeeStep }}</b>/{{ maxEmployeeStep + ' ' + $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</p>
          <h1>{{ $t('employee.step_' + currentEmployeeStep) }}</h1>
        </div>
        <div v-show="currentEmployeeStep == 1" id="step-1">
          <p>{{ $t('employee.text_1') }}</p>
          <label for="first-name">{{ $t('form.first_name') }}</label>
          <input type="text" id="first-name" v-model="contractData.employee.first_name" @change="isValid" />
          <label for="last-name">{{ $t('form.last_name') }}</label>
          <input type="text" id="last-name" v-model="contractData.employee.last_name" @change="isValid" />
          <label for="email">{{ $t('form.email') }}</label>
          <input type="email" id="email" v-model="contractData.employee.email" @change="isValid" />
        </div>

        <div v-show="currentEmployeeStep == 2" id="step-2">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_2') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 2)"><legend>{{ getInfoText(1, 2) }}</legend></i>
          </div>
          <fieldset :data-field="contractData.job_function.employment_type">
            <label for="wage-1" class="inputgroup">
              <input type="radio" id="wage-1" name="type-of-employment" value="fulltime" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_fulltime')}}</span>
            </label>
            <label for="hourly" class="inputgroup">
              <input type="radio" id="hourly" name="type-of-employment" value="hourly" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_hourly')}}</span>
            </label>
            <label for="internship" class="inputgroup">
              <input type="radio" id="internship" name="type-of-employment" value="internship" v-model="contractData.job_function.employment_type" @change="isValid" />
              <span>{{$t('employee.employment_type_internship')}}</span>
            </label>
          </fieldset>
          <div v-if="contractData.job_function.employment_type == 'hourly'" class="row auto-layout mt-2">
            <label for="hourly-wage" class="w-50"> {{$t('employee.gross_wage_hour')}}<br> <sup>{{$t(gwiPercentage ? 'employee.gross_wage_incl' : 'employee.gross_wage_excl')}}</sup> </label>
            <div class="input-group w-50">
              <span>€</span> <input type="number" id="hourly-wage" v-model="contractData.job_function.salary" @change="isValid" />
            </div>
          </div>
          <div v-else-if="contractData.job_function.employment_type == 'internship'" class="row auto-layout mt-2">
            <label for="internship-wage" class="w-50"> {{$t('employee.internship_wage')}}<br><sup>{{$t('employee.per_month')}}</sup> </label>
            <div class="input-group w-50">
              <span>€</span> <input type="number" id="internship-wage" v-model="contractData.job_function.salary" @change="isValid" />
            </div>
          </div>
          <div v-else-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'" class="auto-layout mt-2">
            <div class="row auto-layout">
              <label for="pt-wage" class="w-50">
                {{$t('employee.gross_wage_month')}}
              </label>
              <div class="input-group w-50">
                <span>€</span> <input type="text" id="pt-wage" v-model="contractData.job_function.salary" @change="isValid" />
              </div>
            </div>
            <div class="row">
              <label>{{ $t('employee.roster_1') }}</label>
              <div>
                <div class="row employee-schedule">
                  <small class="col"></small>
                  <div class="col" v-for="weekday in weekdays" :key="weekday">{{weekday}}</div>
                </div>
                <div class="row employee-schedule">
                  <small class="col">Week 1</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[n-1]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[4+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
                <div class="row employee-schedule mt-1">
                  <small class="col">Week 2</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[6+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[11+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <label>{{ $t('employee.hours') }}</label>
              <div type="number" class="optional-disabled">{{getHoursCleaned(contractData.job_function.hours)}}</div>
            </div>
          </div>
          <div class="row" v-if="contractData.job_function.employment_type && showSkilledWorker">
            <label class="col-auto" :style="{width: '30%'}">{{ $t('employee.profession') }}</label>
            <fieldset class="col btn-group optional" :style="{'padding-left': '0.4rem', 'padding-right': '2.5rem'}" :data-field="contractData.job_function.skilled_worker">
              <input id='profession1' type="radio" name="profession" class="btn-check" :value="1" v-model="contractData.job_function.skilled_worker" />
              <label for="profession1" class="btn btn-sm">{{$t('employee.profession_1')}}</label>
              <input id='profession2' type="radio" name="profession" class="btn-check" :value="0" v-model="contractData.job_function.skilled_worker" />
              <label for="profession2" class="btn btn-sm">{{$t('employee.profession_2')}}</label>
              <input id='profession3' type="radio" name="profession" class="btn-check" :value="null" v-model="contractData.job_function.skilled_worker" />
              <label for="profession3" class="btn btn-sm">{{$t('employee.profession_3')}}</label>
            </fieldset>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 3" id="step-3">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_3') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 3)"><legend>{{ getInfoText(1, 3) }}</legend></i>
          </div>
          <div class="auto-layout">
            <div class="row">
              <label for="job-title">{{ $t('employee.job_title') }}</label>
              <select id="job-title" class="optional" v-model="contractData.job_function.debtor_function_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="func in jobFunctions" :key="func.id" :value="func.id" @click="contractData.job_function.name = func.name">{{ func.name }}</option>
              </select>
            </div>
            <div class="row" v-if="showReferenceAlt">
              <label for="reference_number">{{ $t('employee.reference_number') }}</label>
              <input type="text" id="reference_number" class="optional" v-model="contractData.job_function.reference_number" />
            </div>
            <div class="row">
              <label for="department">{{ $t('employee.department') }}</label>
              <select id="department" class="optional" v-model="contractData.job_function.debtor_department_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="dep in jobDepartments" :key="dep.id" :value="dep.id" @click="contractData.job_function.department = dep.name">{{ dep.name }}</option>
              </select>
            </div>
            <button type="button" class="d-none" id="sync-func-button" @click="syncJobFunctions"></button>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 1" id="step-5">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_4') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 4)"><legend>{{ getInfoText(1, 4) }}</legend></i>
          </div>
          <fieldset :data-field="contractData.job_function.fixed_term">
            <label for="contract-1" class="inputgroup">
              <input type="radio" id="contract-1" name="type-of-contract" :value="1" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <span>{{$t('employee.contract_limited')}}</span>
            </label>
            <label for="contract-2" class="inputgroup">
              <input type="radio" id="contract-2" name="type-of-contract" :value="0" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <span>{{$t('employee.contract_unlimited')}}</span>
            </label>
          </fieldset>
          <div v-if="contractData.job_function.fixed_term !== null" class="ps-4 mt-2">
            <div class="row">
              <div class="col-6">
                <sup>{{$t('employee.contract_date_start')}}</sup>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <input type="date" v-model="contractData.job_function.start_date" @change="isValid($event); setProbationTime()" />
              </div>
            </div>
            <div class="row" v-show="contractData.job_function.fixed_term">
              <div class="col-6">
                <sup>{{$t('employee.contract_date_end')}}</sup>
              </div>
            </div>
            <div class="row" v-if="contractData.job_function.fixed_term">
              <div class="col-6">
                <select @change="setContractEndTime" v-model="contractData.job_function.fixed_term_expiration_month">
                  <option value="0"> - </option>
                  <option v-for="n in 12" :value="n" :key="n">{{ n + ' ' + $tc('form.month', n) }}</option>
                </select>
              </div>
              <div class="col-6">
                <input type="date" v-model="contractData.job_function.fixed_term_expiration_date" @change="isValid" />
              </div>
            </div>
            <div class="d-flex auto-layout" v-if="isValidProbationOption(1)">
              <label class="w-75">{{ $t('employee.contract_has_trial') }}</label>
              <fieldset class="btn-group w-25" :data-field="contractData.job_function.probation">
                <input id='contract-probation-1' type="radio" name="trial-yes" class="btn-check" :value="1" v-model="contractData.job_function.probation" @change="isValid" />
                <label for="contract-probation-1" class="btn btn-sm">{{$t('form.yes')}}</label>
                <input id='contract-probation-2' type="radio" name="trial-no" class="btn-check" :value="0" v-model="contractData.job_function.probation" @change="isValid" />
                <label for="contract-probation-2" class="btn btn-sm">{{$t('form.no')}}</label>
              </fieldset>
            </div>
            <div v-if="contractData.job_function.probation" class="ps-0 mt-2">
              <div class="row">
                <label for="prob-enddate" class="mt-2">{{ $t('employee.prob_end_date') }}</label>
                <div class="d-flex gap-2" style="width: calc(100% - 195px)">
                  <select class="w-50" @change="setProbationTime" v-model="contractData.job_function.probation_expiration_month">
                    <option value="0"> - </option>
                    <option value="1" v-if="isValidProbationOption(1)">{{ 1 + ' ' + $tc('form.month', 1) }}</option>
                    <option value="2" v-if="isValidProbationOption(2)">{{ 2 + ' ' + $tc('form.month', 2) }}</option>
                  </select>
                  <input id="prob-enddate" type="date" v-model="contractData.job_function.probation_expiration_date" class="w-50" @change="isValid" />
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <label>{{ $t('employee.contract_upload_now') }}</label>
              <fieldset class="btn-group" :data-field="contractData.generate_contract">
                <input id='contract-upload-1' type="radio" name="contract-upload" class="btn-check" :value="0" v-model="contractData.generate_contract" @change="isValid" />
                <label for="contract-upload-1" class="btn btn-sm">{{$t('employee.direct_upload')}}</label>
                <input id='contract-upload-2' type="radio" name="contract-upload" class="btn-check" :value="1" v-model="contractData.generate_contract" @change="isValid" />
                <label for="contract-upload-2" class="btn btn-sm">{{$t('employee.generate_contract')}}</label>
              </fieldset>
            </div>
            <UploadModule v-if="contractData.generate_contract === 0" :model="(contractData.files ? contractData.files[0] : contractData.document)"
              title="Heb je al een contract van deze werknemer" description="Upload hem door hem in dat vak te slepen of <u>klik hier</u> om hem te zoeken"
              type="pdf" :owner="'contractFile'" ref="contractFileUpload" @addFile="(e) => addContractFile(e, 0)" @removeFile="removeContractFile" />
            <template v-else-if="contractData.generate_contract === 1">
              <div class="row">
                <label for="template">{{ $t('employee.template') }}</label>
                <div>
                  <select id="template" v-model="contractData.template_id" @change="isValid">
                    <option :value="null" disabled>{{ $t('form.select') }}</option>
                    <option v-for="template in companyTemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row" v-show="attachments.length > 0">
                <label>{{ $t('employee.attachments') }}</label>
                <div>
                  <div v-for="field, index in contractData.attachments" :key="index" class="responsive-list">
                    <select v-model="contractData.attachments[index]" @change="isValid">
                      <option value="" disabled>{{ $t('form.select') }}</option>
                      <option v-for="attachment in companyAttachments" :key="attachment.id" :value="attachment.id">{{ attachment.name }}</option>
                    </select>
                    <button type="button" class="btn" @click="responsiveListControl(contractData.attachments, index)"> <i class="fa fa-close text-danger"></i> </button>
                  </div>
                  <p v-show="contractData.attachments.length == 0">{{ $t('form.no_selected', [$t('navigation.attachments')]) }}</p>
                </div>
              </div>
              <div class="d-flex justify-content-center my-2" v-if="attachments.length > 0">
                <button type="button" class="btn action-button w-75" @click="responsiveListControl(contractData.attachments)"> {{ $t('employee.add_attachment') }} </button>
              </div>
              <div class="row">
                <label for="contractdate_early">{{ $t('employee.contract_date') }}</label>
                <div> <input type="date" class="optional" id="contractdate_early" v-model="contractData.generate_date" @change="isValid" /> </div>
              </div>
              <div class="row">
                <label for="contractplace_early">{{ $t('employee.contract_place') }}</label>
                <div> <input type="text" class="optional" id="contractplace_early" v-model="contractData.generate_place" @change="isValid" /> </div>
              </div>
            </template>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 4" id="step-4">
          <div class="row mb-3">
            <b class="col-11">{{ $t('employee.text_5') }}</b> <i class="col-1 far fa-circle-question" v-show="getInfoText(1, 5)"><legend>{{ getInfoText(1, 5) }}</legend></i>
          </div>
          <div class="auto-layout">
            <div class="row comp-travel">
              <label class="pt-3">{{$t('employee.comp_travel')}}</label>
              <fieldset class="btn-group" :data-field="contractData.job_function.travel_compensation_type">
                <input id='tc1' type="radio" name="tc_type" class="btn-check" value="none" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                <input id='tc2' type="radio" name="tc_type" class="btn-check" value="distance" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = '0.23'" />
                <label for="tc2" class="btn btn-sm">{{$t('employee.travel_compensation_type_2')}}</label>
                <input id='tc3' type="radio" name="tc_type" class="btn-check" value="monthly" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc3" class="btn btn-sm">{{$t('employee.travel_compensation_type_3')}}</label>
                <input id='tc4' type="radio" name="tc_type" class="btn-check" value="calculate" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                <label for="tc4" class="btn btn-sm">{{$t('employee.travel_compensation_type_4')}}</label>
              </fieldset>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row" v-if="contractData.job_function.travel_compensation_type == 'monthly'">
              <label></label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-1" type="number" class="optional" v-model="contractData.job_function.travel_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
            <template v-else-if="contractData.job_function.travel_compensation_type == 'calculate'">
              <div class="row">
                <label class="pe-0">{{$t('employee.travel_compensation_days')}}</label>
                <div> <input id="travelcomp-2" type="number" v-model="contractData.job_function.travel_compensation_days" @change="isValid($event); calcTravelCompensation()" /> </div>
              </div>
              <div class="row">
                <label>{{$t('employee.travel_compensation_distance')}}</label>
                <div class="input-group">
                  <input id="travelcomp-3" type="number" v-model="contractData.job_function.travel_compensation_distance" @change="isValid($event); calcTravelCompensation()" />
                  <span class="w-25 p-2">km</span>
                </div>
              </div>
              <div class="ms-1 ps-2">
                {{ $t('employee.travel_compensation_calculated') + (contractData.job_function.travel_compensation ? contractData.job_function.travel_compensation.toFixed(2) : '-') }}
              </div>
            </template>
            <br>
          </div>
          <div class="auto-layout">
            <div class="row comp-travel">
              <label class="pt-1">{{$t('employee.work_home_compensation')}}</label>
              <fieldset class="btn-group" :data-field="workHomeType">
                <input id='whc1' type="radio" name="whc_type" class="btn-check" value="none" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(false)" />
                <label for="whc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                <input id='whc2' type="radio" name="whc_type" class="btn-check" value="monthly" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                <label for="whc2" class="btn btn-sm">{{$t('employee.work_home_compensation_type_1')}}</label>
                <input id='whc3' type="radio" name="whc_type" class="btn-check" value="calculate" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                <label for="whc3" class="btn btn-sm">{{$t('employee.work_home_compensation_type_2')}}</label>
              </fieldset>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row" v-if="workHomeType == 'monthly'">
              <label></label>
              <div class="input-group">
                <span>€</span> <input id="wcomp-1" type="number" v-model="contractData.job_function.work_days_at_home_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
            <template v-else-if="workHomeType == 'calculate'">
              <div class="row">
                <label class="pe-0">{{$t('employee.work_home_compensation_days')}}</label>
                <div> <input id="wcomp-2" type="number" v-model="contractData.job_function.total_work_days_at_home" @change="isValid($event); calcWorkHomeCompensation()" /> </div>
              </div>
              <div class="ms-1 ps-2">
                {{ $t('employee.work_home_compensation_calculated') + (contractData.job_function.work_days_at_home_compensation ? Number(contractData.job_function.work_days_at_home_compensation).toFixed(2) : '-') }}
              </div>
            </template>
            <br>
          </div>
          <div class="auto-layout-3">
            <div class="row">
              <label for="travelcomp-2">{{$t('employee.comp_expenses')}}</label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-2" type="number" class="optional" v-model="contractData.job_function.expenses_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
          </div>
          <div class="auto-layout-3">
            <div class="row">
              <label for="travelcomp-3">{{$t('employee.comp_phone')}}</label>
              <div class="input-group">
                <span>€</span> <input id="travelcomp-3" type="number" class="optional" v-model="contractData.job_function.phone_compensation" @change="isValid" />
              </div>
              <span>{{ $t('employee.per_month') }}</span>
            </div>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 2" id="step-8">
          <h5>{{ $t('employee.choose_articles') }}</h5>
          <div v-for="article, index in articles" :key="index" class="">
            <input type="checkbox" v-model="article.show" @change="setArticleIndexes" :id="'article-'+index">
            <label :for="'article-'+index" class="fw-bold d-inline">{{ article.prefix + ' ' + article.index + ' ' + article.name }}</label>
            <p v-show="article.show" v-html="article.text"></p>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 5 && currentEmployeeContractStep == 3" id="step-9">
          <h5>{{ $t('employee.review_contract') }}</h5>
          <div id="pell" class="pell" />
          <div v-if="contractData.attachments && contractData.attachments.length > 0">
            <h5 class="mt-4">{{ $tc('navigation.attachments', 2) }}</h5>
            <p v-for="(att, attIndex) in contractData.attachments" :key="attIndex" v-html="showAttachment(att)"></p>
          </div>
        </div>

        <div v-show="currentEmployeeStep == 6" id="step-6">
          <h1>{{ $t('employee.employee_added') }}</h1>
          <h2>{{ $t('employee.naw_title') }}</h2>
          <p>{{ $t('employee.naw_text') }}</p>
        </div>
        <div v-show="currentEmployeeStep > 6" id="step-7">
          <h1>{{ $t('employee.mail_employee') }}</h1>
          <label for="to-line">{{ $t('employee.mail_to') }}</label>
          <input type="email" id="to-line" v-model="mail.email_to" @change="isValid" />
          <label for="subject">{{ $t('employee.mail_subject') }}</label>
          <input type="text" id="subject" v-model="mail.subject" @change="isValid" />
          <label for="message">{{ $t('form.message') }}</label>
          <textarea id="message" :rows="setTextAreaRows(mail.email_body)" v-model="mail.email_body"></textarea>
        </div>
      </div>
      <div class="col-5 p-4">
        <div class="position-sticky" v-show="!sendingCorrections">
          <h2>{{ $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_1') }}</li>
            <li :class="getListClass(2)">{{ $t('employee.step_2') }}</li>
            <li :class="getListClass(3)">{{ $t('employee.step_3') }}</li>
            <li :class="getListClass(4)">{{ $t('employee.step_4') }}</li>
            <li :class="getListClass(5)">{{ $t('employee.step_5') }}</li>
            <li :class="getListClass(6)" v-if="!isEditing">{{ $t('employee.step_6') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-6 text-start">
        <button type="button" class="btn px-5 secondary-button" @click="currentEmployeeStep--" v-if="currentEmployeeStep > 1 && currentEmployeeStep < 6 && currentEmployeeContractStep == 1">{{ $t('form.back') }}</button>
        <button type="button" class="btn px-5 secondary-button" @click="currentEmployeeContractStep--" v-else-if="currentEmployeeContractStep > 1">{{ $t('form.back') }}</button>
        <button type="button" class="btn px-5 secondary-button" @click="formSection = 2" v-else-if="sendingCorrections">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-end" v-if="contractData.generate_contract === 1 && currentEmployeeStep == maxEmployeeStep">
        <button type="button" class="btn px-5 btn-success" @click="saveSection(1, false)" v-if="currentEmployeeContractStep == maxContractStep && isEditing">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 action-button" v-else @click="setNextContract">{{ $t('form.next') }}</button>
      </div>
      <div class="col-6 text-end" v-else>
        <button type="button" class="btn px-5 btn-success" v-if="currentEmployeeStep > (maxEmployeeStep + 1)" @click="sendMailForNAW">{{ $t('form.send') }}</button>
        <button type="button" class="btn px-5 btn-success" @click="saveSection(1, false)" v-else-if="isEditing && currentEmployeeStep == maxEmployeeStep">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="currentEmployeeStep <= maxEmployeeStep">{{ $t('form.next') }}</button>
        <div class="d-flex gap-2 justify-content-end" v-else>
          <button type="button" class="btn px-5 secondary-button" @click="setMailInfo">{{ $t('employee.mail_employee') }}</button>
          <button type="button" class="btn px-5 action-button" @click="formSection = 2">{{ $t('employee.fillself') }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container p-0" v-else-if="formSection == 2">
    <div class="row">
      <div class="col-7 p-4 border-right">
        <div v-if="currentNAWStep <= maxNAWStep">
          <p v-show="!isEditing"><b>{{ currentNAWStep }}</b>/{{ maxNAWStep + ' ' + $t('employee.naw') }}</p>
          <h1>{{ $t('employee.step_naw_' + currentNAWStep) }}</h1>
        </div>
        <div v-show="currentNAWStep == 1" id="step-1">
          <div class="mt-2" v-if="nawData.effective_date !== undefined">
            <label for="startdate">{{ $t('form.effective_date') }}</label>
            <input type="date" id="effdate" v-model="nawData.effective_date" @change="isValid" />
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <label for="postal-code">{{ $t('form.first_name') }}</label>
              <input type="text" id="naw-first-name" v-model="nawData.first_name" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="city">{{ $t('form.last_name') }}</label>
              <input type="text" id="naw-last-name" v-model="nawData.last_name" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="street">{{ $t('form.street') }}</label>
              <input type="text" id="street" v-model="nawData.address.street" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="housenr">{{ $t('form.house_nr') }}</label>
              <input type="number" id="housenr" v-model="nawData.address.house_number" @change="isValid" />
            </div>
            <div class="col-3">
              <label for="addition">{{ $t('form.suffix') }}</label>
              <input type="text" id="addition" class="optional" v-model="nawData.address.prefix" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="postal-code">{{ $t('form.postal_code') }}</label>
              <input type="text" id="postal-code" v-model="nawData.address.postal_code" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="city">{{ $t('form.city') }}</label>
              <input type="text" id="city" v-model="nawData.address.place" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="email-2">{{ $t('form.email') }}</label>
              <input type="email" id="email-2" v-model="nawData.email" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="phone">{{ $t('form.phone') }}</label>
              <input type="tel" id="phone" v-model="nawData.phone_number" @change="isValid" />
            </div>
            <div class="col-4">
              <label for="dob">{{ $t('form.dob') }}</label>
              <input type="date" id="dob" v-model="nawData.birth_date" data-notinfuture="" @change="isValid" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="pob">{{ $t('form.pob') }}</label>
              <input type="text" id="pob" v-model="nawData.birth_place" @change="isValid" />
            </div>
            <div class="col-6">
              <label for="bsn">{{ $t('form.bsn') }}</label>
              <input type="bsn" id="bsn" v-model="nawData.bsn_number" placeholder="000000000" @change="validateBSN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="nationality">{{ $t('form.nationality') }}</label>
              <select id="nationality" v-model="nawData.nationality" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id"> {{ nationality.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label for="iban">{{ $t('form.iban') }}</label>
              <input type="iban" id="iban" v-model="nawData.iban" @change="validateIBAN($event.target)" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="country">{{ $t('form.country') }}</label>
              <select id="country" v-model="nawData.birth_country_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="country in countries" :key="country.id" :value="country.id"> {{ country.name }} </option>
              </select>
            </div>
            <div class="col-6">
              <label>{{ $t('form.gender') }}</label>
              <fieldset class="col-sm-4 btn-group w-100" :data-field="nawData.gender">
                <input id='gt1' type="radio" name="gtype" class="btn-check" value="male" v-model="nawData.gender" @change="isValid" />
                <label for="gt1" class="btn btn-sm">{{$t('form.gender_male')}}</label>
                <input id='gt2' type="radio" name="gtype" class="btn-check" value="female" v-model="nawData.gender" @change="isValid" />
                <label for="gt2" class="btn btn-sm">{{$t('form.gender_female')}}</label>
                <input id='gt3' type="radio" name="gtype" class="btn-check" value="undefined" v-model="nawData.gender" @change="isValid" />
                <label for="gt3" class="btn btn-sm">{{$t('form.gender_na')}}</label>
              </fieldset>
            </div>
          </div>
        </div>

        <div v-show="currentNAWStep == 2" id="step-2">
          <p class="row"></p>
          <div class="row" v-if="isEditing">
            <label class="col-sm-8">{{ $t('employee.upload_new_files') }}</label>
            <fieldset class="col-sm-4 btn-group" :data-field="nawData.new_identity_files">
              <input id='nidyes' type="radio" name="nidtype" class="btn-check" :value="1" v-model="nawData.new_identity_files" @change="isValid" />
              <label for="nidyes" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='nidno' type="radio" name="nidtype" class="btn-check" :value="0" v-model="nawData.new_identity_files" @change="isValid" />
              <label for="nidno" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <template v-if="!isEditing || nawData.new_identity_files">
            <UploadModule :model="docTypeOne" title="Voorkant" description="van het identiteitsbewijs" type="pdf" :owner="'contractFile2'" ref="contractFileUpload2"
                          @addFile="(e) => addNAWFile(e, 0)" @removeFile="removeNAWFile" />
            <UploadModule :model="docTypeTwo" title="Achterkant" description="van het identiteitsbewijs" type="pdf" :owner="'contractFile3'" ref="contractFileUpload3"
                          @addFile="(e) => addNAWFile(e, 1)" @removeFile="removeNAWFile" />
            <div class="row">
              <p class="w-40 pt-2">{{ $t('employee.id_type') }}</p>
              <select id="doc-type" class="w-60" v-model="nawData.identification_nmbrs_document_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="documentType in documentTypes" :key="documentType.id" :value="documentType.id">{{ documentType.description }}</option>
              </select>
            </div>
            <div class="row">
              <p class="w-40 pt-2">{{ $t('employee.id_type_enddate') }}</p>
              <input class="w-60" type="date" id="dob2" v-model="nawData.identification_expiration_date" data-notinpast="" @change="isValid" />
            </div>
          </template>
        </div>

        <div v-show="currentNAWStep == 3" id="step-3">
          <p>{{ $t('employee.lhk_text') }}</p>
          <div class="row">
            <p class="col-sm-6 mb-0 pt-1">{{ $t('employee.lhk_question_2') }}</p>
            <fieldset class="col-sm-6 btn-group" :data-field="contractData.job_function.tax_compensation">
              <input id='lhk1' type="radio" name="lhk" class="btn-check" :value="1" v-model="contractData.job_function.tax_compensation" @change="isValid($event); nawData.tax_compensation = 1" />
              <label for="lhk1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='lhk2' type="radio" name="lhk" class="btn-check" :value="0" v-model="contractData.job_function.tax_compensation" @change="isValid($event); nawData.tax_compensation = 0" />
              <label for="lhk2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <br />
          <div>
            <p>{{ $t(contractData.job_function.tax_compensation ? 'employee.lhv_employer_mandatory' : 'employee.lhv_employer') }}</p>
            <UploadModule :model="docTypeThree" title="Loonheffingsverklaring" description="kunt u hier uploaden" type="pdf" :owner="'contractFile4'" ref="contractFileUpload4"
                          @addFile="(e) => addNAWFile(e, 2)" @removeFile="removeNAWFile" />
          </div>
        </div>
        <div v-show="currentNAWStep == 4" id="step-4">
          <h1>{{ $t('employee.step_naw_4') }}</h1>
          <p>De medewerker is toegevoegd.</p>
        </div>
      </div>
      <div class="col-5 p-4">
        <div class="position-sticky">
          <h2>{{ $t((isEditing ? 'form.edit' : 'form.add'), [$t('navigation.employees')]) }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_naw_1') }}</li>
            <li :class="getListClass(2)">{{ $t('employee.step_naw_2') }}</li>
            <li :class="getListClass(3)" v-if="!isEditing">{{ $t('employee.step_naw_3') }}</li>
            <li :class="getListClass(4)" v-if="!isEditing">{{ $t('employee.step_naw_4') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-6 text-start">
        <button type="button" class="btn px-5 secondary-button" @click="currentNAWStep--" v-if="currentNAWStep > 1">{{ $t('form.back') }}</button>
        <button type="button" class="btn px-5 secondary-button" v-else-if="!isEditing" @click="formSection = 1">{{ $t('form.back') }}</button>
      </div>
      <div class="col-6 text-end">
        <template v-if="isEditing">
          <button type="button" class="btn px-5 secondary-button" @click="sendNAWCorrections" v-if="currentNAWStep == 1 && nawData.employee_status_id == 3">{{ $t('employee.save_send_mail') }}</button>
          <button type="button" class="btn px-5 action-button ms-2" @click="setNext" v-if="currentNAWStep == 1">{{ $t('form.next') }}</button>
          <button type="button" class="btn px-5 btn-success" @click="saveSection(2, false)" v-else>{{ $t('form.save') }}</button>
        </template>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="currentNAWStep <= maxNAWStep">{{ $t('form.next') }}</button>
        <button type="button" class="btn px-5 action-button" v-else @click="backToOverview">{{ $t('employee.back_to_overview') }}</button>
      </div>
    </div>
  </div>

  <div class="container p-0" v-else>
    <div class="row">
      <div class="col-7 p-4 border-right auto-layout">
        <div v-if="showContractNext" class="row">
          <p v-show="!facOnly"><b>{{ currentContractStep }}</b>/{{ maxContractStep + ' ' + $t('employee.fillcontract') }}</p>
          <h1>{{ $t('employee.step_contract_' + currentContractStep) }}</h1>
        </div>
        <div v-show="currentContractStep == 1" id="step-1">
          <div class="row" v-if="contractData.effective_date !== undefined">
            <label for="startdate">{{ $t('form.effective_date') }}</label>
            <input type="date" id="effdate" v-model="contractData.effective_date" @change="isValid" />
          </div>
          <div class="row" v-if="!salaryOnly && !sendDirectlyToNMBRS">
            <label>{{ $t('form.edit') }}</label>
          <div>
            <input type="checkbox" v-model="editMoreData" id="article-0">
            <label for="article-0" class="fw-bold d-inline">Meer aanpassen</label>
            <template v-if="editMoreData">
              <br> <input type="checkbox" v-model="editSalary" id="article-1">
              <label for="article-1" class="fw-bold d-inline">salaris</label>
              <br> <input type="checkbox" v-model="editSchedule" id="article-2">
              <label for="article-2" class="fw-bold d-inline">rooster</label>
              <br> <input type="checkbox" v-model="editFunction" id="article-3">
              <label for="article-3" class="fw-bold d-inline">functie</label>
              <br> <input type="checkbox" v-model="editDepartment" id="article-4">
              <label for="article-4" class="fw-bold d-inline">afdeling</label>
              <br> <input type="checkbox" v-model="editCompensations" id="article-5">
              <label for="article-5" class="fw-bold d-inline">overige vergoedingen</label>
            </template>
          </div>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editFunction))">
            <label for="job-title">{{ $t('employee.job_title') }}</label>
            <select id="job-title" class="optional" v-model="contractData.job_function.debtor_function_id" @change="isValid">
              <option :value="null" disabled>{{ $t('form.select') }}</option>
              <option v-for="func in jobFunctions" :key="func.id" :value="func.id" @click="contractData.job_function.name = func.name">{{ func.name }}</option>
            </select>
          </div>
          <div class="row" v-if="!salaryOnly && !professionEdit">
            <label for="reference_number">{{ $t('employee.reference_number') }}</label>
            <input type="text" id="reference_number" class="optional" v-model="contractData.job_function.reference_number" />
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editDepartment))">
            <label for="department">{{ $t('employee.department') }}</label>
            <select id="department" class="optional" v-model="contractData.job_function.debtor_department_id" @change="isValid">
              <option :value="null" disabled>{{ $t('form.select') }}</option>
              <option v-for="dep in jobDepartments" :key="dep.id" :value="dep.id" @click="contractData.job_function.department = dep.name">{{ dep.name }}</option>
            </select>
          </div>
          <div class="row" v-if="!salaryOnly">
            <label>{{ $t('employee.contract_type') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.fixed_term">
              <input id='ct1' type="radio" name="contract_type" class="btn-check" :value="1" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <label for="ct1" class="btn btn-sm">{{$t('employee.contract_limited')}}</label>
              <input id='ct2' type="radio" name="contract_type" class="btn-check" :value="0" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <label for="ct2" class="btn btn-sm">{{$t('employee.contract_unlimited')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
            <label>{{ $t('employee.probation') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.probation">
              <input id='probation1' type="radio" name="probation_type" class="btn-check" :value="1" v-model="contractData.job_function.probation" @change="isValid" />
              <label for="probation1" class="btn btn-sm">{{$t('employee.probation_1')}}</label>
              <input id='probation2' type="radio" name="probation_type" class="btn-check" :value="0" v-model="contractData.job_function.probation" @change="isValid" />
              <label for="probation2" class="btn btn-sm">{{$t('employee.probation_2')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
            <label>{{ $t('employee.employment') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.employment_type">
              <input id='employment1' type="radio" name="employment" class="btn-check" value="fulltime" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment1" class="btn btn-sm">{{$t('employee.employment_type_fulltime')}}</label>
              <input id='employment2' type="radio" name="employment" class="btn-check" value="hourly" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment2" class="btn btn-sm">{{$t('employee.employment_type_hourly')}}</label>
              <input id='employment3' type="radio" name="employment" class="btn-check" value="internship" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment3" class="btn btn-sm">{{$t('employee.employment_type_internship')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly">
            <label for="startdate">{{ $t('form.start_date') }}</label>
            <input type="date" id="startdate" v-model="contractData.job_function.start_date" @change="isValid" />
          </div>
          <div class="row" v-if="!salaryOnly && contractData.job_function.fixed_term">
            <label for="enddate">{{ $t('form.end_date') }}</label>
            <input type="date" id="enddate" v-model="contractData.job_function.fixed_term_expiration_date" @change="isValid" />
          </div>
          <div class="row" v-if="!salaryOnly && showSkilledWorker">
            <label>{{ $t('employee.profession') }}</label>
            <fieldset class="btn-group optional" :data-field="contractData.job_function.skilled_worker">
              <input id='profession1' type="radio" name="profession" class="btn-check" :value="1" v-model="contractData.job_function.skilled_worker" />
              <label for="profession1" class="btn btn-sm">{{$t('employee.profession_1')}}</label>
              <input id='profession2' type="radio" name="profession" class="btn-check" :value="0" v-model="contractData.job_function.skilled_worker" />
              <label for="profession2" class="btn btn-sm">{{$t('employee.profession_2')}}</label>
              <input id='profession3' type="radio" name="profession" class="btn-check" :value="null" v-model="contractData.job_function.skilled_worker" />
              <label for="profession3" class="btn btn-sm">{{$t('employee.profession_3')}}</label>
            </fieldset>
          </div>
          <template v-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'">
            <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
              <label>{{ $t('employee.roster_1') }}</label>
              <div>
                <div class="row employee-schedule">
                  <small class="col"></small>
                  <div class="col" v-for="weekday in weekdays" :key="weekday">{{weekday}}</div>
                </div>
                <div class="row employee-schedule">
                  <small class="col">Week 1</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[n-1]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[4+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
                <div class="row employee-schedule mt-1">
                  <small class="col">Week 2</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[6+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[11+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
              </div>
            </div>
            <div class="row mt-2" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
              <label>{{ $t('employee.hours') }}</label>
              <div type="number" class="optional-disabled">{{getHoursCleaned(contractData.job_function.hours)}}</div>
            </div>
          </template>
          <div class="row salaryclass" v-if="(!professionEdit || (professionEdit && editSalary))">
            <label for="salary">{{ $t('employee.salary_' + (contractData.job_function.hours	== 38 ? 'fulltime' : 'parttime')) }}</label>
            <input type="number" id="salary" v-model="contractData.job_function.salary" @change="isValid" />
          </div>

          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations))">
            <label>{{ $t('employee.compensation') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.additional_compensations">
              <input id='compensation1' type="radio" name="compensation" class="btn-check" :value="1" v-model="contractData.job_function.additional_compensations" @change="isValid" />
              <label for="compensation1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='compensation2' type="radio" name="compensation" class="btn-check" :value="0" v-model="contractData.job_function.additional_compensations" @change="isValid" />
              <label for="compensation2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>

          <template v-if="contractData.job_function.additional_compensations">
            <div class="auto-layout">
              <div class="row comp-travel" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations))">
                <label class="ps-0 pt-1">{{$t('employee.comp_travel')}}</label>
                <fieldset class="btn-group ps-0" :data-field="contractData.job_function.travel_compensation_type">
                  <input id='tc1' type="radio" name="tc_type" class="btn-check" value="none" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                  <input id='tc2' type="radio" name="tc_type" class="btn-check" value="distance" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = '0.23'" />
                  <label for="tc2" class="btn btn-sm">{{$t('employee.travel_compensation_type_2')}}</label>
                  <input id='tc3' type="radio" name="tc_type" class="btn-check" value="monthly" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc3" class="btn btn-sm">{{$t('employee.travel_compensation_type_3')}}</label>
                  <input id='tc4' type="radio" name="tc_type" class="btn-check" value="calculate" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc4" class="btn btn-sm">{{$t('employee.travel_compensation_type_4')}}</label>
                </fieldset>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations)) && contractData.job_function.travel_compensation_type == 'monthly'">
                <label></label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-1" type="number" class="optional" v-model="contractData.job_function.travel_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
              <template v-else-if="contractData.job_function.travel_compensation_type == 'calculate'">
                <div class="row" v-if="!salaryOnly">
                  <label class="pe-0">{{$t('employee.travel_compensation_days')}}</label>
                  <div> <input id="travelcomp-2" type="number" v-model="contractData.job_function.travel_compensation_days" @change="isValid($event); calcTravelCompensation()" /> </div>
                </div>
                <div class="row" v-if="!salaryOnly">
                  <label>{{$t('employee.travel_compensation_distance')}}</label>
                  <div class="input-group">
                    <input id="travelcomp-3" type="number" v-model="contractData.job_function.travel_compensation_distance" @change="isValid($event); calcTravelCompensation()" />
                    <span class="w-25 p-2">km</span>
                  </div>
                </div>
                <div class="ms-1 ps-2">
                  {{ $t('employee.travel_compensation_calculated') + (contractData.job_function.travel_compensation ? contractData.job_function.travel_compensation.toFixed(2) : '-') }}
                </div>
              </template>
              <br>
            </div>
            <div class="auto-layout">
              <div class="row comp-travel">
                <label class="ps-0 pt-1">{{$t('employee.work_home_compensation')}}</label>
                <fieldset class="btn-group ps-0" :data-field="workHomeType">
                  <input id='whc1' type="radio" name="whc_type" class="btn-check" value="none" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(false)" />
                  <label for="whc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                  <input id='whc2' type="radio" name="whc_type" class="btn-check" value="monthly" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                  <label for="whc2" class="btn btn-sm">{{$t('employee.work_home_compensation_type_1')}}</label>
                  <input id='whc3' type="radio" name="whc_type" class="btn-check" value="calculate" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                  <label for="whc3" class="btn btn-sm">{{$t('employee.work_home_compensation_type_2')}}</label>
                </fieldset>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row" v-if="!salaryOnly && workHomeType == 'monthly'">
                <label></label>
                <div class="input-group">
                  <span>€</span> <input id="wcomp-1" type="number" v-model="contractData.job_function.work_days_at_home_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
              <template v-else-if="workHomeType == 'calculate'">
                <div class="row">
                  <label class="pe-0">{{$t('employee.work_home_compensation_days')}}</label>
                  <div> <input id="wcomp-2" type="number" v-model="contractData.job_function.total_work_days_at_home" @change="isValid($event); calcWorkHomeCompensation()" /> </div>
                </div>
                <div class="ms-1 ps-2">
                  {{ $t('employee.work_home_compensation_calculated') + (contractData.job_function.work_days_at_home_compensation ? Number(contractData.job_function.work_days_at_home_compensation).toFixed(2) : '-') }}
                </div>
              </template>
              <br>
            </div>
            <div class="auto-layout-3">
              <div class="row">
                <label for="travelcomp-2">{{$t('employee.comp_expenses')}}</label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-2" type="number" class="optional" v-model="contractData.job_function.expenses_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row">
                <label for="travelcomp-3">{{$t('employee.comp_phone')}}</label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-3" type="number" class="optional" v-model="contractData.job_function.phone_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
            </div>
          </template>
        </div>

        <div v-show="currentContractStep == 2" id="step-2">
          <div class="row">
            <label>{{ $t('employee.generate_contract') }}</label>
            <fieldset class="btn-group" :data-field="contractData.generate_contract">
              <input id='gen1' type="radio" name="generate_contract" class="btn-check" :value="1" v-model="contractData.generate_contract" @change="isValid" />
              <label for="gen1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='gen2' type="radio" name="generate_contract" class="btn-check" :value="0" v-model="contractData.generate_contract" @change="isValid" />
              <label for="gen2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <template v-if="contractData.generate_contract === 1">
            <div class="row">
              <label for="template">{{ $t('employee.template') }}</label>
              <select id="template" v-model="contractData.template_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="template in companyTemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
              </select>
            </div>
            <div class="row" v-if="attachments.length > 0">
              <label>{{ $t('employee.attachments') }}</label>
              <div v-if="contractData.attachments.length > 0">
                <div v-for="field, index in contractData.attachments" :key="index" class="responsive-list">
                  <select v-model="contractData.attachments[index]" @change="isValid">
                    <option value="" disabled>{{ $t('form.select') }}</option>
                    <option v-for="attachment in companyAttachments" :key="attachment.id" :value="attachment.id">{{ attachment.name }}</option>
                  </select>
                  <button type="button" class="btn" @click="responsiveListControl(contractData.attachments, index); refreshContract()">
                    <i class="fa fa-close text-danger"></i>
                  </button>
                </div>
              </div>
              <div v-else>{{ $t('form.no_selected', [$t('navigation.attachments')]) }}</div>
            </div>
            <div class="row my-2" v-if="attachments.length > 0">
              <label></label>
              <button type="button" class="btn action-button" @click="responsiveListControl(contractData.attachments); refreshContract()"> {{ $t('employee.add_attachment') }} </button>
            </div>
            <div class="row">
              <label for="contractdate">{{ $t('employee.contract_date') }}</label>
              <input type="date" id="contractdate" v-model="contractData.generate_date" @change="isValid" />
            </div>
            <div class="row">
              <label for="contractplace">{{ $t('employee.contract_place') }}</label>
              <input type="text" id="contractplace" v-model="contractData.generate_place" @change="isValid" />
            </div>
          </template>
          <template v-else-if="contractData.generate_contract === 0">
            <UploadModule :model="(contractData.files ? contractData.files[0] : contractData.document)" title="Heb je al een contract van deze werknemer"
              description="Upload hem door hem in dat vak te slepen of <u>klik hier</u> om hem te zoeken" type="pdf" :owner="'contractFile4'" ref="contractFileUpload4"
              @addFile="(e) => addContractFile(e, 0)" @removeFile="removeContractFile" />
          </template>
        </div>

        <div v-show="currentContractStep == 3 && contractData.generate_contract === 1" id="step-3">
          <h5>{{ $t('employee.choose_articles') }}</h5>
          <div v-for="article, index in articles" :key="index" class="">
            <input type="checkbox" v-model="article.show" @change="setArticleIndexes" :id="'article-'+index">
            <label :for="'article-'+index" class="fw-bold d-inline">{{ article.prefix + ' ' + article.index + ' ' + article.name }}</label>
            <p v-show="article.show" v-html="article.text"></p>
          </div>
        </div>
        <div v-show="currentContractStep == 3 && contractData.generate_contract === 0" id="step-3b">
          <h5>{{ $t('employee.complete_1') }}</h5>
          <p> {{ $t('employee.complete_2') }} </p>
        </div>
        <div v-show="currentContractStep == 9" id="step-9">
          <h1>{{ $t('employee.mail_employee') }}</h1>
          <label for="to-line">{{ $t('employee.mail_to') }}</label>
          <input type="email" id="to-line" v-model="mail.email_to" @change="isValid" />
          <label for="subject">{{ $t('employee.mail_subject') }}</label>
          <input type="text" id="subject" v-model="mail.subject" @change="isValid" />
          <label for="message">{{ $t('form.message') }}</label>
          <textarea id="message" :rows="setTextAreaRows(mail.email_body)" v-model="mail.email_body"></textarea>
        </div>
        <div v-show="currentContractStep == 4" id="step-4">
          <h5>{{ $t('employee.review_contract') }}</h5>
          <div id="pell" class="pell" />
          <div v-if="contractData.attachments && contractData.attachments.length > 0">
            <h5 class="mt-4">{{ $tc('navigation.attachments', 2) }}</h5>
            <p v-for="(att, attIndex) in contractData.attachments" :key="attIndex" v-html="showAttachment(att)"></p>
          </div>
        </div>
        <div class="modal fade" id="facModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('employee.changes_mail_header') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body"> {{ $t('employee.changes_mail_text') }} </div>
              <div class="modal-footer">
                <button type="button" class="btn secondary-button" data-bs-dismiss="modal" @click="saveSection(3, false)">{{ $t('form.no') }}</button>
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="setFACMail">{{ $t('form.yes') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 p-4">
        <div class="position-sticky" v-if="!facOnly">
          <h2>{{ $t('employee.fillcontract') }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_contract_1') }}</li>
            <li :class="getListClass(2)" >{{ $t('employee.step_contract_2') }}</li>
            <li :class="getListClass(3)" v-if="contractData.generate_contract">{{ $t('employee.step_contract_3') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-5 text-start">
        <button type="button" class="btn px-5 secondary-button" v-if="!isEditing || (currentContractStep != 1) || currentContractStep > 2" @click="onContractBack">{{ $t('form.back') }}</button>
      </div>
      <div class="col-7 text-end">
        <button type="button" class="btn px-5 btn-success" v-if="isEditing && facOnly && currentContractStep == 1" @click="setFACModal">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 btn-success" v-else-if="facOnly && currentContractStep == 9" @click="sendMailChanges">{{ $t('form.send') }}</button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="showContractNext">{{ $t('form.next') }}</button>
        <template v-else>
          <button type="button" v-if="nawData.birth_date" class="btn px-5 action-button mx-3" @click="saveSection(3, 'download')"><i class="fa fa-download"></i>&ensp;{{ $t('overview.download_pdf') }}</button>
          <button type="button" class="btn px-5 btn-success" @click="saveSection(3, 'save')">{{ $t('employee.send_contract') }}</button>
        </template>
        <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#facModal">{{ $t('form.save') }}</button>
      </div>
    </div>
  </div>
  <hr class="text-white">
</template>

<script>
  import UploadModule from '@/components/UploadModule'
  import contractService from '@/services/ContractService'
  import countryService from '@/services/CountryService'
  import nationalityService from '@/services/NationalityService'
  import employeeService from '@/services/EmployeeService'
  import infoService from '@/services/InfoService'
  import templateService from '@/services/TemplateService'
  import documentTypeService from '@/services/DocumentTypeService'
  import companyService from '@/services/CompanyService'
  import fileService from '@/services/FileService'
  import IBAN from "iban";
  import moment from 'moment'
  import store from '@/store/user'
  import router from '@/router'
  import pell from 'pell'
  import pellCSS from 'pell/dist/pell.min.css'
  export default {
    name: 'Home',
    components: {
      UploadModule
    },
    computed: {
      showContractNext() {return this.contractData.generate_contract ? this.currentContractStep <= this.maxContractStep : this.currentContractStep < 3 },
      showReference() { return window.currentCustomer && window.currentCustomer.use_refrence },
      showSkilledWorker() { return window.currentCustomer && window.currentCustomer.use_skilled_worker },
      gwiPercentage() { return (window.currentCustomer && window.currentCustomer.holiday_pay_hourly_percentage) ? window.currentCustomer.holiday_pay_hourly_percentage : false }
    },
    data() {
      return{
        salaryOnly: false,
        editMoreData: false,
        editSalary: false,
        editSchedule: false,
        editFunction: false,
        editDepartment: false,
        editCompensations: false,
        professionEdit: false,
        isEditing: false,
        facOnly: false,
        currentEmployeeStep: 1,
        maxEmployeeStep: 5,
        currentNAWStep: 1,
        maxNAWStep: 3,
        currentContractStep: 1,
        maxContractStep: 3,
        currentEmployeeContractStep: 1,
        docTypeOne: null,
        docTypeTwo: null,
        docTypeThree: null,
        formSection: 1,
        sendingCorrections: false,
        templateHeader: "",
        templateFooter: "",
        articles: [],
        countries: [],
        nationalities: [],
        templates: [],
        attachments: [],
        companyTemplates: [],
        companyAttachments: [],
        jobFunctions: [],
        jobDepartments: [],
        documentTypes: [],
        infoTexts: {},
        companyData: {},
        isEmployeeFillingInNAW: false,
        shouldGenerateContract: true,
        sendDirectlyToNMBRS: false,
        workHomeType: "none",
        weekdays: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
        nawData: {
            "email": "",
            "first_name": "",
            "initials": "",
            "prefix": null,
            "last_name": "",
            "birth_place": "",
            "birth_date": "",
            "nationality": null,
            "gender": "",
            "description": null,
            "bsn_number": "",
            "iban": "",
            "phone_number": "",
            "identification_number": null,
            "identification_expiration_date": null,
            "identification_uploaded": null,
            "birth_country_id": null,
            "company_id": null,
            "identification_file_id": null,
            "identification_nmbrs_document_id": null,
            "employee_status_id": null,
            "address": {
                "postal_code": "",
                "house_number": "",
                "prefix": null,
                "street": "",
                "place": "",
                "latitude": null,
                "longitude": null,
                "country_id": null
            },
            "files": [null, null, null],
            "tax_compensation": 0,
            "tax_compensation_date": null,
            "tax_compensation_signature": null
        },
        oldContractData: {},
        contractData: {
          "employee": {
              "first_name": "",
              "last_name": "",
              "email": "",
              "phone_number": ""
          },
          "job_function": {
              "name": "",
              "reference_number": "",
              "department": "",
              "start_date": "",
              "fixed_term": null,
              "fixed_term_expiration_date": null,
              "probation": false,
              "probation_expiration_date": "",
              "probation_expiration_month": 0,
              "fixed_term_expiration_month": 0,
              "employment_type": "",
              "skilled_worker": null,
              "timetable": "8,8,8,8,8,0,0,8,8,8,8,8,0,0",
              "hours": 40,
              "salary": "",
              "travel_compensation_type": "none",
              "tax_compensation": 0,
              "tax_compensation_days": null,
              "tax_compensation_distance": null,
              "additional_compensations": null,
              "travel_compensation": null,
              "travel_compensation_days": null,
              "travel_compensation_distance": null,
              "expenses_compensation": null,
              "phone_compensation": null,
              "direct_upload": null,
              "has_wage_tax_credit": 0,
              "total_work_days_at_home": null,
              "work_days_at_home_compensation": null,
              "debtor_function_id": null,
              "debtor_department_id": null
          },
          "generate_contract": null,
          "generate_date": null,
          "generate_place": null,
          "template_id": null,
          "document_view": null,
          "document": null,
          "signature": null,
          "sync": null,
          "company_id": null,
          "attachments": []
        },
        mail: { email_to: "", subject: "", email_body: "" },
        schedule: ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'],
        dict: {},
        pellEditor: null
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast'))
                  validityCheck = date > Date.now();
                else
                  validityCheck = date < Date.now();
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                case "bsn":
                  validityCheck = this.validateBSN(el);
                  shouldShowvalidity = false;
                  break;
                case "iban":
                  validityCheck = this.validateIBAN(el);
                  shouldShowvalidity = false;
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const steps = [0, this.currentEmployeeStep, this.currentNAWStep, this.currentContractStep];
        const step = steps[this.formSection];
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        else if(this.currentNAWStep == 2 && !this.nawData.files[0] && (!this.nawData.files || !this.nawData.files[0] ) && (!this.isEditing || this.nawData.new_identity_files)){
          alert(this.$t('errors.identity'));
          isValid = false;
        }
        else if(this.currentNAWStep == 3 && this.contractData.job_function.tax_compensation && !this.nawData.files[2] ){
          alert(this.$t('errors.lhk'));
          isValid = false;
        }
        return isValid;
      },
      showAttachment(id){
        const attachment = this.attachments.find(att => att.id == id);
        return attachment ? attachment.contents.replaceAll("\n", "<br />") : "-";
      },
      showReferenceAlt() { 
        return window.currentCustomer && window.currentCustomer.use_refrence;
      },
      setNext(){
        if(!this.validate()) return;
        if(this.formSection == 1){
          if(this.currentEmployeeStep == 4){
            this.companyData = {};
            companyService.show(store.getters.getCurrentCompanyId).then(response => this.companyData = response.data).catch(e => console.log(e));
          }
          if(this.currentEmployeeStep == 1 && !this.isEditing){
            let timetable = store.getters.getDefaultSchedule;
            if(timetable)
            {  
              timetable = timetable.replaceAll('.00', '');
              this.schedule = timetable.split(',');
            }
            this.contractData.job_function.timetable = timetable;
            this.calculateTotalHours(null);
            this.$nextTick(this.initPell);
          }
          //Pre-fill some NAW data
          if(this.currentEmployeeStep == this.maxEmployeeStep){
            this.nawData.first_name = this.contractData.employee.first_name;
            this.nawData.last_name = this.contractData.employee.last_name;
            if(!this.nawData.company_id) this.nawData.company_id = store.getters.getCurrentCompanyId;
            this.contractData.company_id = this.nawData.company_id;
            this.contractData.job_function.company_id = this.contractData.company_id;
            this.nawData.email = this.contractData.employee.email;
            this.mail.email_to = this.contractData.employee.email;
            this.nawData.files = [null, null, null];
            this.sendContractData(1);
          }
          this.currentEmployeeStep++;
        }
        else if(this.formSection == 2){
          if(!this.nawData.company_id) this.nawData.company_id = store.getters.getCurrentCompanyId;
          if(this.currentNAWStep == this.maxNAWStep){
            this.sendNAWData(2);
          }
          this.currentNAWStep++;
        }
        else if(this.formSection == 3){
          if(this.currentContractStep == 1)
            this.$nextTick(this.initPell);
          if(this.currentContractStep == 2 && this.contractData.generate_contract)
            this.setArticles();
          else if(this.currentContractStep == 3 && this.contractData.generate_contract)
            this.setTemplate();
          this.currentContractStep++;
        }
        window.scrollTo(0, 0);
      },
      setNextContract(){
        if(!this.contractData.template_id){
          alert(this.$t('errors.choose_template'));
          document.getElementById('template').classList.add('is-invalid');
          return;
        }
        if(this.currentEmployeeContractStep == 3){
          this.setNext();
          return;
        }
        if(!this.validate()) return;
        if(this.currentEmployeeContractStep == 1){
          this.nawData.first_name = this.contractData.employee.first_name;
          this.nawData.last_name = this.contractData.employee.last_name;
          if(!this.contractData.job_function.company_id) this.contractData.job_function.company_id = this.contractData.company_id;
          this.nawData.email = this.contractData.employee.email;
          this.setArticles();
        }
        else if(this.currentEmployeeContractStep == 2){
          this.shouldGenerateContract = true;
          this.setTemplate();
        }
        if(this.currentEmployeeContractStep == 1 || this.currentEmployeeContractStep == 2){
          this.currentEmployeeContractStep++;
        }
        window.scrollTo(0, 0);
      },
      sendContractData(nextFormStep){
        if(this.contractData.files){
          this.contractData.files = this.setFiles(this.contractData.files);
        }
        if(this.contractData.id){
          this.contractData.no_sign_request = nextFormStep == 'download';
          contractService.update(this.contractData).then((response) => {
            if(nextFormStep == 'download'){
              this.downloadPDF(this.contractData);
            }
            else if(nextFormStep == 'save' || !nextFormStep){
              window.location.href = window.location.origin + "/employees";
            }
            else{
              this.contractData.id = response.data.id;
              this.formSection = nextFormStep;
            }
          }).catch(this.toastError);
        }
        else{
          contractService.store(this.contractData).then((response) => {
            if(nextFormStep){
              this.contractData.id = response.data.id;
              this.nawData.id = response.data.employee_id;
              this.nawData.employee_status_id = 3;
            }
            employeeService.update(this.nawData).then(() => {
              if(nextFormStep){
                this.formSection = nextFormStep;
                this.nawData.employee_status_id = 4;
              }
              else 
                window.location.href = window.location.origin + "/employees";
            }).catch(this.toastError);
          }).catch(this.toastError);
        }
      },
      sendNAWData(nextFormStep){
        this.nawData.country_id = this.nawData.address.country_id;
        if(this.nawData.files){
          this.nawData.files = this.setFiles(this.nawData.files);
        }
        this.nawData.bsn_number = ("000000000" + this.nawData.bsn_number).slice(-9);
        if(this.sendDirectlyToNMBRS){
          if(!nextFormStep){
            this.nawData.contract = this.contractData;
          }
          const service = this.isEmployeeFillingInNAW ? employeeService : contractService;
          const data = this.isEmployeeFillingInNAW ? this.nawData : this.contractData;
          this.contractData.external_id = this.contractData.employee.external_id;
          service.updateNMBRS(store.getters.getCurrentCustomerId, data).then(() => {
            window.location.href = window.location.origin + "/employees";
          }).catch(this.toastError);
        }
        else if(this.nawData.id){
          employeeService.update(this.nawData).then(() => {
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError);
        }
        else{
          employeeService.store(this.nawData).then(() => {
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError);
        }
      },
      syncJobFunctions(){
        this.getJobFunctions();
        const currentCompId = store.getters.getCurrentCompanyId;
        this.companyTemplates = this.templates.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
        this.companyAttachments = this.attachments.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
        this.getTemplates();
      },
      loadData(){
          countryService.index().then(response => {
              this.countries = response.data;
              this.countries.sort(function(x,y){ return x.name =='Nederland'  ? -1 : y.name =='Nederland' ? 1 : 0; });
          }).catch(this.toastError);
          nationalityService.index().then(response => {
              this.nationalities = response.data;
              this.nationalities.sort(function(x,y){ return x.name =='Nederlandse'  ? -1 : y.name =='Nederlandse' ? 1 : 0; });
          }).catch(this.toastError);
          infoService.index().then(response => {
              response.data.forEach(info => this.infoTexts[info.identifier] = info.description);
          }).catch(this.toastError);
          documentTypeService.index().then(response => this.documentTypes = response.data).catch(this.toastError);
      },
      getTemplates(){
          templateService.index().then(response => {
              this.attachments = response.data.filter(template => template.is_appendage);
              this.templates = response.data.filter(template => !template.is_appendage);
              const currentCompId = store.getters.getCurrentCompanyId;
              this.companyTemplates = this.templates.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
              this.companyAttachments = this.attachments.filter(template => template.companies.find(comp => comp.id == currentCompId) != null);
          }).catch(this.toastError);
      },
      getInfoText(section, step){
        const identifier = 'employee_' + section + '_' + step;
        return this.infoTexts[identifier] ? this.infoTexts[identifier] : '';
      },
      getJobFunctions(){        
        this.jobFunctions = store.getters.getDepartments;
        this.jobDepartments = store.getters.getFunctions;
      },
      getListClass(step){
        const currentStep = this.formSection == 1 ? this.currentEmployeeStep : (this.formSection == 2 ? this.currentNAWStep : this.currentContractStep);
        return step < currentStep ? "check" : (step == currentStep ? "active" : "inactive");
      },
      calculateTotalHours(e){
        let total = 0;
        this.schedule.forEach(hours => {
          const value = Number(hours);
          total += ((!hours || isNaN(value)) ? 0 : value);
        });
        total = total / 2;
        this.contractData.job_function.timetable = this.schedule.join(',');
        this.contractData.job_function.hours = total;
      },
      getSumBSN(bsn) {
        let a = parseInt(bsn[0]) * 9;
        let b = parseInt(bsn[1]) * 8;
        let c = parseInt(bsn[2]) * 7;
        let d = parseInt(bsn[3]) * 6;
        let e = parseInt(bsn[4]) * 5;
        let f = parseInt(bsn[5]) * 4;
        let g = parseInt(bsn[6]) * 3;
        let h = parseInt(bsn[7]) * 2;
        let i = parseInt(bsn[8]) * -1;

        let sum = a + b + c + d + e + f + g + h + i;
        return sum;
      },
      setMailInfo(e){
        this.currentEmployeeStep++;
        this.mail.subject = "Uitnodiging " + this.getCompany();
        this.mail.email_body = 
`Beste ${this.contractData.employee.first_name} ${this.contractData.employee.last_name},

Voordat we jouw salaris kunnen betalen hebben wij jouw persoonlijke gegevens nodig.
Via onderstaande knop kun je deze snel en gemakkelijk aanleveren. 
Wij gaan vertrouwelijk met de gegevens om en slaan deze alleen op in jouw personeelsdossier.
Let op: zolang jij geen gegevens hebt aangeleverd, kunnen wij jou niet verlonen.

Bedankt!`
      },
      responsiveListControl(list, removeIndex = null){
        if(removeIndex == null)
          list.push('');
        else{
          list.splice(removeIndex, 1);
        }
      },
      sendNAWCorrections(){
        const dataToSend = {...this.nawData};
        dataToSend.birth_date = "";
        employeeService.update(dataToSend).then((response) => {
          this.formSection = 1;
          this.currentEmployeeStep = 9;
          this.sendingCorrections = true;
          this.contractData = {id: response.data.contract.id, employee: {first_name: dataToSend.first_name, last_name: dataToSend.last_name}, job_function: {}};
          this.mail.email_to = dataToSend.email;
          this.setMailInfo();
        }).catch(this.toastError);
      },
      saveSection(section, nextFormStep){
        if(!this.validate()) return;
        if(section == 3){
          this.nawData.employee_status_id = 1;
          if(nextFormStep === 'download'){
            this.sendContractData(nextFormStep);
          }
          else{
            if(nextFormStep === 'save') {
              this.sendContractData(nextFormStep);
            }
            this.sendNAWData(3);
          }
        }
        else if(section == 2){
          this.sendNAWData(nextFormStep);
        }
        else{
          this.sendContractData(nextFormStep);
        }
      },
      onContractBack(){
        if(this.isEditing && this.currentContractStep == 9) this.currentContractStep = 1;
        else if(!this.isEditing && this.currentContractStep == 1) this.formSection = 2;
        else this.currentContractStep--;
      },
      setFACModal(e){
        if(this.validate()){
          e.target.nextElementSibling.click();
        }
      },
      setFACMail(){
        let changesString = "";
        for(let field in this.oldContractData.job_function){
          const newVal = this.contractData.job_function[field];
          const oldVal = this.oldContractData.job_function[field];
          if(newVal != oldVal){
            if(field == 'employment_type'){
              changesString += "Verandering dienstverband: was eerst " + this.$t('employee.employment_type_' + oldVal) + ", veranderd naar " + this.$t('employee.employment_type_' + newVal) + ".\n";
            }
            if(field == 'salary'){
              const oldPer = this.oldContractData.job_function.employment_type == 'hourly' ? ' per uur' : ' per maand';
              const newPer = this.contractData.job_function.employment_type == 'hourly' ? ' per uur' : ' per maand';
              changesString += "Verandering salaris: was eerst €" + oldVal + oldPer + ", veranderd naar €" + newVal + newPer + ".\n";
            }
            if(field == 'name'){
              const newText = document.getElementById("job-title").selectedOptions[0].text;
              changesString += "Verandering functie: uw nieuwe functie is " + newText + ".\n";
            }
            if(field == 'department'){
              const newText = document.getElementById("department").selectedOptions[0].text;
              changesString += "Verandering afdeling: uw nieuwe afdeling is " + newText + ".\n";
            }
            if(field == 'timetable'){
              let newSchedule = "";
              const pretext = ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
              for(let index in this.schedule){
                if(index == 0) newSchedule += "--- Week 1 ---\n";
                if(index == 7) newSchedule += "--- Week 2 ---\n";
                newSchedule += (pretext[index % 7]  + " " + (this.schedule[index] ? this.schedule[index] : "0") + " uur\n");
              }
              changesString += "Nieuw rooster, uw nieuwe tijden zijn als volgt:\n" + newSchedule;
            }
          }
        }
        this.mail.email_to = this.contractData.employee.email;
        this.mail.email_body = "Beste " + this.contractData.employee.first_name + ", \n\n" + "Er zijn wijzigingen gemaakt in uw functie, uren en/of salaris.\nHieronder staan de wijzigingen op een rij:\n\n" + changesString + "\n" + "Met vriendelijke groet, IBEO";
        this.currentContractStep = 9;
      },
      sendMailChanges(){
        if(!this.validate()) return;
        contractService.mailChanges({
          "email_to": this.mail.email_to,
          "email_body": this.mail.email_body,
          "email_subject": this.mail.subject,
        }).then((response) => this.saveSection(3, false, true)).catch(this.toastError);
      },
      sendMailForNAW(){
        if(!this.validate()) return;
        contractService.mail(this.mail, this.contractData.id).then((response) => this.saveSection(3, false, true)).catch(this.toastError);
      },
      setTemplate(){
        if(!this.shouldGenerateContract){
          // NOODOPLOSSING - GAAT SOMS OM ONBEKENDE REDEN FOUT IN DE BACK-END
          if(this.formSection == 3 && this.nawData){
            this.contractData.document = this.contractData.document.replaceAll("[ BSN-NUMMER ]", this.nawData.bsn_number).replaceAll("[ TELEFOONNUMMER ]", this.nawData.phone_number);
          }
          // EINDE NOODOPLOSSING
          this.pellEditor.content.innerHTML = this.contractData.document;
          return;
        }
        let text = this.decodeTemplate(this.templateHeader);
        text += '<br>';
        let index = 1;
        this.articles.forEach(article => {
          if(article.show){
            text += '<br>';
            text += ('<b>' + article.prefix + ' ' + article.index + ' ' + article.name + '</b>');
            text += '<br>';
            text += article.text.replaceAll(/\u200b\d+/gm, index);
            text += '<br>';
            index++;
          }
        });
        text += '<br>';
        text += this.decodeTemplate(this.templateFooter);
        this.contractData.document = text;
        if(this.pellEditor){
          this.pellEditor.content.innerHTML = text;
        }
      },
      setProbationTime(){
        const startDate = this.contractData.job_function.start_date;
        if(!startDate) return;

        const months = this.contractData.job_function.probation_expiration_month;
        this.contractData.job_function.probation_expiration_date = moment(this.contractData.job_function.start_date).add(months, 'M').subtract(1, "days").format('YYYY-MM-DD');
      },
      setContractEndTime(){
        const startDate = this.contractData.job_function.start_date;
        if(!startDate) return;

        const months = this.contractData.job_function.fixed_term_expiration_month;
        this.contractData.job_function.fixed_term_expiration_date = moment(this.contractData.job_function.start_date).add(months, 'M').subtract(1, "days").format('YYYY-MM-DD');
      },
      calcTravelCompensation(){
        this.contractData.job_function.travel_compensation = Math.ceil(1.6407 * Number(this.contractData.job_function.travel_compensation_distance) * 
            Number(this.contractData.job_function.travel_compensation_days) * 100) / 100; // (((214 * 0.23) / 5) * 2) / 12) = 1.6407
      },
      calcWorkHomeCompensation(){
        this.contractData.job_function.work_days_at_home_compensation = (214 * 2.15 * Number(this.contractData.job_function.total_work_days_at_home)) / 12;
      },
      setWorkHomeCompensation(hasComp){
        this.contractData.job_function.has_wage_tax_credit = hasComp;
        this.contractData.job_function.total_work_days_at_home = null;
        this.contractData.job_function.work_days_at_home_compensation = null;
      },
      sign() {
        this.nawData.tax_compensation_signature = this.$refs.signaturePad.toDataURL();
        this.nawData.tax_compensation_date = moment();
        this.nawData.tax_compensation = 1;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      validateBSN(userBSN) {
        let bsn = ("000000000" + userBSN.value).slice(-9);

        let valid = true;
        if (!userBSN.value || isNaN(userBSN.value) || Number(userBSN.value) == 0 || this.getSumBSN(bsn) % 11)
          valid = false;

        this.showValidity(userBSN, valid, true);
        return valid;
      },
      validateIBAN(userIBAN) {
        this.nawData.iban = this.nawData.iban.toUpperCase().replaceAll(/[^A-Z0-9]/g, '');
        let valid = userIBAN.value && IBAN.isValid(this.nawData.iban);
        this.showValidity(userIBAN, valid, true);
        return valid;
      },
      setTextAreaRows(text){
        const rows = text.replace(/[^\n]/g, '').length + 1;
        return rows < 4 ? 4 : rows;
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },
      decodeTemplate(input, articleNumber = 0){
        let output = input;
        //Set variables
        const varMatches = output.matchAll(/##([A-Za-z0-9_]+?)##/gs);
        for(let match of varMatches){
          if(match[1] == 'artikel'){
            output = output.replace(match[0], "\u200b" + articleNumber );
            continue;
          }
          const value = this.dict[match[1]];
          if(!this.pellEditor)
            output = output = output.replace(match[0], (value === null || value === '' || value === undefined || value === 'Invalid date') ? ' [ NOG NIET BEPAALD ] ' : value);
          else
            output = output.replace(match[0], value === null ? '' : value);
        }
        //Set groups
        const grMatches = output.matchAll(/<([A-Za-z ]+?):([A-Za-z ]+?)>(.+?)<\/.+?>/gs);
        for(let match of grMatches){
          const field = this.dict[match[1]];
          const value = this.dict[match[2]];
          const inner = match[3];
          if(field == value || (field == null && value === false))
            output = output.replace(match[0], inner);
          else
            output = output.replace(match[0], '');
        }
        output = output.trim().replaceAll('\n', '<br/>');
        return output;
      },
      setArticles(){
        this.setDict();
        this.articles = [];
        const template = this.templates.find(template => template.id == this.contractData.template_id);
        let templateHeader = template.contents.replace(/<artikel>.+/gs, '');
        let templateFooter = template.contents.replace(templateHeader, '');
        const regex = /<artikel>(.+?)<\/artikel>/gs;
        const matches = template.contents.matchAll(regex);
        let i = 0;
        for(let match of matches){
          const matchText = match[1];
          if(matchText[0] == '<') continue;
          const nameString = matchText.match(/\*(.+?)\*/)[0];
          let text = matchText.replace(nameString, '');
          text = this.decodeTemplate(text, i + 1);
          const nameArray = nameString.slice(1, -1).split('##');
          this.articles.push({prefix: nameArray[0], index: (i + 1), name: nameArray[2], text: text, show: true});
          templateFooter = templateFooter.replace(match[0], '');
          i++;
        }
        this.templateHeader = templateHeader;
        this.templateFooter = templateFooter;
      },
      setArticleIndexes(e){
        this.refreshContract();
        let index = 1;
        this.articles.forEach(article => {
          if(article.show){
            article.index = index;
            article.text = article.text.replaceAll(/\u200b\d+/gm, "\u200b" + index);
            index++;
          }
          else{
            article.index = '-';
          }
        })
      },
      getCompany(){
        const name = store.getters.getCurrentCompanyName;
        if(!name){
          const el = document.getElementById('company-select');
          if(!el || el.selectedIndex === -1) return '-';
          return el.options[el.selectedIndex].innerHTML;
        }
        return name;
      },
      setDict(){
        const c = this.contractData;
        const n = (this.contractData.employee && this.contractData.employee.address) ? this.contractData.employee : this.nawData;
        const country = this.countries.find(country => country.id == (n.birth_country_id ? n.birth_country_id : 166));
        const compAddress = c.company && c.company.address ? JSON.parse(c.company.address) : this.companyData.address;
        const jobFunction = this.jobFunctions.find(func => func.id == c.job_function.debtor_function_id);
        const hasNAWInfo = this.pellEditor && this.isEditing;
        this.dict = {
          werkgever_naam: c.company ? c.company.name : this.companyData.name,
          werkgever_adres: compAddress ? (compAddress.Street + ' ' + compAddress.HouseNumber) : '[ ADRES WERKGEVER ]',
          werkgever_postcode: compAddress ? compAddress.PostalCode : '[ POSTCODE WERKGEVER ]',
          werkgever_plaats: compAddress ? compAddress.City : '[ PLAATS WERKGEVER ]',
          werkgever_vertegenwoordiger_naam: c.company ? c.company.representative : this.companyData.representative,
          werknemer_voornaam: hasNAWInfo ? n.first_name : '[ VOORNAAM ]',
          werknemer_voorletters: hasNAWInfo ? (n.first_name[0] + '.') : '[ INITIAAL ]',
          werknemer_voorvoegsel: n.prefix ? n.prefix : ' ',
          werknemer_achternaam: hasNAWInfo ? n.last_name : '[ ACHTERNAAM ]',
          werknemer_straat: hasNAWInfo ? n.address.street : '[ STRAAT ]',
          werknemer_huisnr: hasNAWInfo ? n.address.house_number : '[ HUISNUMMER ]',
          werknemer_toevoeg: hasNAWInfo ? n.address.prefix : '[ TOEVOEGING ]',
          werknemer_postcode: hasNAWInfo ? n.address.postal_code : '[ POSTCODE ]',
          werknemer_plaats: hasNAWInfo ? n.address.place : '[ WOONPLAATS ]',
          werknemer_geboortedatum: hasNAWInfo ? this.formatDate(n.birth_date, 'shortDate') : '[ GEBOORTEDATUM ]',
          werknemer_geboorteplaats: hasNAWInfo ? n.birth_place : '[ GEBOORTEPLAATS ]',
          werknemer_geboorteland: hasNAWInfo ? country.name : '[ GEBOORTELAND ]',
          startdatum: c.job_function.start_date ? this.formatDate(c.job_function.start_date, 'shortDate') : '[ STARTDATUM ]',
          einddatum: this.formatDate(c.job_function.fixed_term_expiration_date, 'shortDate'),
          uren: this.getHoursCleaned(c.job_function.hours),
          proeftijd_maand: c.job_function.probation_expiration_month,
          functie_naam: jobFunction ? jobFunction.name : (c.job_function.name ? c.job_function.name : '[ NOG NIET BEPAALD ]'),
          referentienummer: c.job_function.reference_number ? c.job_function.reference_number : '(geen referenties opgegeven)',
          salaris: c.job_function.salary ? c.job_function.salary : 0,
          salaris_per: c.job_function.employment_type == 'hourly' ? 'uur' : (c.job_function.employment_type == 'internship' ? 'stageperiode' : 'maand'),
          artikel: '',
          onkosten: c.job_function.expenses_compensation ? c.job_function.expenses_compensation : 0,
          reiskosten: c.job_function.travel_compensation ? Number(c.job_function.travel_compensation).toFixed(2) : 0,
          reiskosten_per: c.job_function.travel_compensation_type == 'distance' ? 'kilometer' : 'maand',
          telefoon: c.job_function.phone_compensation ? c.job_function.phone_compensation : 0,
          contract_plaats: c.generate_place,
          contract_datum: c.generate_date ? this.formatDate(c.generate_date, 'shortDate') : moment().format('DD-MM-YYYY'),
          werknemer_iban: hasNAWInfo ? n.iban : '[ IBAN ]',
          werknemer_email: c.employee.email,
          werknemer_bsnnummer: hasNAWInfo ? n.bsn_number : '[ BSN-NUMMER ]',
          werknemer_telefoonnr: hasNAWInfo ? n.phone_number : '[ TELEFOONNUMMER ]',
          contracttype: c.job_function.fixed_term,
          'Onbepaalde tijd': 0,
          'Bepaalde tijd': 1,
          vakkracht: c.job_function.skilled_worker,
          'ja': 1,
          'nee': 0,
          dienstverband: c.job_function.hours < 36,
          'Fulltime': false,
          'Parttime': true,
          proeftijd: c.job_function.probation,
          loonheffingskorting: c.job_function.tax_compensation == 1,
          handtekening_werkgever: '##handtekening_werkgever##',
          handtekening_werknemer: '##handtekening_werknemer##',
          'nieuwe pagina': ' nieuwe pagina '
        }
      },
      initPell(){
        if(this.pellEditor != null) return;
        const pellElement = document.getElementById('pell');
        pellElement.innerHTML = '';
        this.$nextTick(() => {
          this.pellEditor = pell.init({
            element: pellElement,
            onChange: html => { this.contractData.document = html; },
            actions: [ 'bold', 'italic', 'underline', 'heading1', 'heading2', 'paragraph', 'olist', 'ulist' ]
          });
        });
      },
      isValidProbationOption(value){
        if(!this.contractData.job_function.start_date || (this.contractData.job_function.fixed_term && !this.contractData.job_function.fixed_term_expiration_date)){
          this.contractData.job_function.probation = false;
          return false;
        }
        if(this.contractData.job_function.fixed_term){
          let d1 = new Date(this.contractData.job_function.start_date);
          let d2 = new Date(this.contractData.job_function.fixed_term_expiration_date);
          d2.setDate(d2.getDate() + 1);
          let months;
          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth();
          const diff = months <= 0 ? 0 : months;
          if(value == 1 && diff < 6 && this.contractData.job_function.probation){
            this.contractData.job_function.probation = false;
          }
          return value == 1 ? diff >= 6 : diff >= 24;
        }
        return true;
      },
      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
      downloadPDF(item) {
        this.item = {...item};
        contractService.download(this.item.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const pdfName = "annemax_" + item.employee.first_name.replace(' ', '-') + '_' + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
            link.setAttribute("download", pdfName + ".pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(this.toastError);
      },
      setProfessionData(data, href){
        console.log(data);
        for(let field in data){
          if(data[field] !== null)
            this.contractData[field] = data[field];
        }
        if(this.contractData.job_function){
          let jobfunction = this.contractData.job_function;
          if(jobfunction.travel_compensation === null)
            jobfunction.travel_compensation_type = 'none';
          else if(jobfunction.travel_compensation === '0.23')
            jobfunction.travel_compensation_type = 'distance';
          else
            jobfunction.travel_compensation_type = 'monthly';
          jobfunction.additional_compensations = (jobfunction.travel_compensation_type != 'none' || jobfunction.phone_compensation || jobfunction.expenses_compensation) ? 1 : 0;
        }
        this.oldContractData = JSON.parse(JSON.stringify(this.contractData));
        this.schedule = this.contractData.job_function.timetable ? this.contractData.job_function.timetable.split(',') : ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'];
        this.calculateTotalHours(null);
        if(href.includes("contract")){
          this.shouldGenerateContract = false;
        }
      },
      backToOverview(){
        this.$router.push({ name: "employees" });
      },
      addNAWFile(file, index){
        fileService.store(file).then(response => {
          this.nawData.files[index] = response.data;
        });
      },
      removeNAWFile(index){
        this.nawData.files[index] = null;
      },
      addContractFile(file, index){
        if(!this.contractData.files) this.contractData.files = [null];
        fileService.store(file).then(response => {
          this.contractData.files[index] = response.data;
        });
      },
      removeContractFile(index){
        this.contractData.files[index] = null;
      },
      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      }
    },
    mounted() {
      window.setTimeout(() => {
        //Get countries and nationalities
        this.loadData();
        this.getTemplates();
        this.getJobFunctions();

        const setNAW = function(response, parent){
          for(let field in response.data){
            if(response.data[field] !== null)
              parent.nawData[field] = response.data[field];
          }
          parent.nawData.files = [null, null, null];
          if(response.data.files){
            parent.docTypeOne = response.data.files[0];
            parent.docTypeTwo = response.data.files[2] ? response.data.files[1] : null;
            parent.docTypeThree = response.data.files[2] ? response.data.files[2] : (response.data.files[1] ? response.data.files[1] : null);
            parent.hasFrontAndBack = response.data.files[1] && response.data.files[2];
          }
          parent.nawData.new_identity_files = 0;
        }

        const href = window.location.href;
        
        if( href.includes("edit-employee") ){
          this.isEditing = true;
          const splitHref = href.split('/');
          const id = splitHref[splitHref.length - 1];
          const hasNMBRSLink = id > 99999;
          this.sendDirectlyToNMBRS = hasNMBRSLink;
          if(href.includes("main")){
            this.formSection = 1;
            this.currentEmployeeStep = 1;
            contractService.show(id).then(response => {
                this.contractData = response.data;
                if(!response.data.attachments){
                  this.contractData.attachments = [];
                }
                this.schedule = this.contractData.job_function.timetable.split(',');
                this.workHomeType = this.contractData.job_function.has_wage_tax_credit ? (this.contractData.job_function.total_work_days_at_home ? "calculate" : "monthly") : "none";
            }).catch(this.toastError);
          }
          else if(href.includes("personalia") || href.includes("naw")){
            this.formSection = 2;
            this.currentNAWStep = 1;
            this.isEmployeeFillingInNAW = href.includes("personalia");
            if(hasNMBRSLink){
              employeeService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                setNAW(response, this);
                this.nawData.effective_date = moment().format('YYYY-MM-DD');
              }).catch(this.toastError);
            }
            else{
              employeeService.show(id).then(response => setNAW(response, this)).catch(this.toastError);
            }
          }
          else{
            if(hasNMBRSLink){
              this.formSection = 2;
              contractService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                  this.setProfessionData(response.data, href);
                  this.contractData.effective_date = moment().format('YYYY-MM-DD');
                  employeeService.showFromNMBRS(id, store.getters.getCurrentCompanyId, store.getters.getCurrentCustomerId).then(response => {
                      for(let field in response.data){
                        if(response.data[field] !== null)
                          this.nawData[field] = response.data[field];
                      }
                      this.oldContractData.job_function.timetable = this.schedule.join(',') + '';
                      if(href.includes("contract")){
                        this.contractData.send_sign_request = true;
                        this.contractData.employer_id = null;
                        this.contractData.company_id = store.getters.getCurrentCompanyId;
                        this.$nextTick(this.initPell);
                      }
                      if(href.includes("profession")){
                        this.contractData.job_function.start_date = moment().format('YYYY-MM-DD');
                        this.contractData.job_function.fixed_term_expiration_date = this.contractData.job_function.fixed_term ? moment().add(1, 'Y').subtract(1, 'days').format('YYYY-MM-DD') : null;
                      }
                  }).catch(this.toastError);
              }).catch(this.toastError);
            }
            else{
              contractService.show(id).then(response => {
                  this.setProfessionData(response.data, href);
                  employeeService.show(response.data.employee_id).then(response => {
                      for(let field in response.data){
                        if(response.data[field] !== null)
                          this.nawData[field] = response.data[field];
                      }
                      this.oldContractData.job_function.timetable = this.schedule.join(',') + '';
                      if(href.includes("contract")) this.$nextTick(this.initPell);
                  }).catch(this.toastError);
              }).catch(this.toastError);
            }


          }
          if(href.includes("profession")){
            this.formSection = 3;
            this.currentContractStep = 1;
            this.facOnly = true;
            this.professionEdit = true;
          }
          if(href.includes("salary")){
            this.salaryOnly= true;
            this.formSection = 3;
            this.currentContractStep = 1;
            this.facOnly = true;
          }
          if(href.includes("contract")){
            this.formSection = 3;
            this.currentContractStep = 1;
            companyService.show(store.getters.getCurrentCompanyId).then(response => this.companyData = response.data).catch(e => console.log(e));
          }
          if(href.includes("new-employee")){
            this.formSection = 1;
          }
        }

        
      }, 100);
    }
  }
</script>